<template>
	<div class="pubpage">
		<!-- <div style="height:1rem" v-if="isfooter==1||isfooter==2"></div> -->
		<!-- <div class="headbox" :class="[nyCss && inwidth>768 ?'ny_css':'',isfooter==3?'headbox3':'']"> -->
		<div class="headbox" v-if="currentPath !== '/construction' && currentPath !== '/culture'">
			<div class="header">
				<div class="headlt" v-if="inwidth > 768" @click="scrollToAnchors">
					<img src="../../static/wenchang/logo.png" alt="">
				</div>
				<div class="headlt" v-if="inwidth <= 768" @click="scrollToAnchors">
					<img src="../../static/wenchang/logo.png" alt="">
				</div>

				<div class="headamo">
					<div class="headlist" v-if="inwidth > 768">
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('one')">大赛简介</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">大赛简介</a>
						</router-link>
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('two')">大赛进程</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">大赛进程</a>
						</router-link>
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('sui')">奖项设置</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">奖项设置</a>
						</router-link>
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('fo')">评选标准</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">评选标准</a>
						</router-link>
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('fv')">参赛须知</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">参赛须知</a>
						</router-link>
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('six')">报名方式</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">报名方式</a>
						</router-link>
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('sw')">参赛声明</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli" @click="scrollToAnchor('et')">参赛声明</a>
						</router-link>
						<!-- <router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli" @click="scrollToAnchor('et')">组织框架</a>
						</router-link>
						<router-link to="/" tag="div" v-else>
							<a class="headli">组织框架</a>
						</router-link> -->
						<router-link to="" tag="div" v-if="currentPath == '/'">
							<a class="headli">获奖公示</a>
						</router-link>
						<router-link to="" tag="div" v-else>
							<a class="headli">获奖公示</a>
						</router-link>
						<!-- <router-link to="/science_child8" tag="div" v-if="currentPath == '/'">
							<a class="headli">作品投票</a>
						</router-link>
						<router-link to="/science_child8" tag="div" v-else>
							<a class="headli">作品投票</a>
						</router-link> -->
						<router-link to="/science_child1" tag="div" v-if="!currentphone">
							<a class="headli">登录 / 注册</a>
						</router-link>
						<router-link to="/science_child3" tag="div" v-if="currentphone">
							<a class="headli">个人中心</a>
							<a class="headli" @click="outlogin">退出登录</a>
						</router-link>
						<!-- <div v-for="(item,index) in navlist" class="headli" :key="index" @click="navChange(index)">
							<router-link :to="item.path" tag="div" class="navitem" :class="navctive===index?'navctive':''">
								<div class="nav_cont">
									{{item.name}}
								</div>
							</router-link>
							<div class="science_nav" v-if="item.subnavList.length>0">
								<div class="science_navLi" v-for="(items,indexs) in item.subnavList" :key='indexs' @click="activeSwitch(index,items.path)">{{items.name}}</div>
							</div>
						</div> -->
					</div>
					<!--  -->
					<div class="lines" @click="navMobile" v-else>
						<!-- @mouseleave="leave()" -->
						<div><img src="../../static/wenchang/33.png" v-if="!navstate" style="width:100%" /></div>
					</div>
				</div>

				<div class="headnav" v-if="inwidth <= 768 && navstate">
					<div class="headnavlist" v-for="(item, index) in navlist" :key="index" v-if="item.subnavList"
						@click="navstatefalse(item)">
						<router-link :to="item.path" tag="div">
							<div style="color: #333; line-height: 51px">{{ item.name }}</div>
						</router-link>
					</div>
				</div>
				<div class="headnavs" v-if="inwidth <= 768 && navstate" @click="navstate = false"></div>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isfooter: 3, //1第一版 2第二版 3第三版
			myState: true,
			hq_str_hk00951: sessionStorage.getItem("hq_str_hk00951"),
			closeNav: true,
			cid: 1,
			aa: false,
			navctive: this.$route.query.navctiveIndex,
			navstate: false,
			webInfo: {},
			nyCss: true, //判断是否为内页
			navlist: [{
				name: '首页',
				path: '/',
				subnavList: 1,
			},
			{
				name: '个人信息',
				path: '/',
				subnavList: 1,
			},
			{
				name: '作品管理',
				path: '/',
				subnavList: 1,
			},
			{
				name: '获奖公示',
				path: '/',
				subnavList: 1,
			},
			{
				name: '登录/注册',
				path: '/science_child1',
				subnavList: localStorage.getItem('phone') ? false : true,
			},
			{
				name: '退出登录',
				path: '/',
				subnavList: localStorage.getItem('phone') ? true : false
			},
			],
			inwidth: window.innerWidth,
			inheight: window.innerHeight,
			footdata: {},
			navbg: false,
			pagea: '', //当前页数
			delivery_asn: '',
			currentphone: '',
		}
	},
	computed: {
		currentPath() {
			return this.$route.path;
		},
		currentName() {
			return this.$route.name;
		}
	},
	created() {

		//解决刷新首页导航变内页导航
		// if (this.$route.path != '/') {
		// 	this.nyCss = true
		// } else {
		// 	this.nyCss = false
		// }
		let that = this;
		window.addEventListener('setItem', () => {
			that.pagea = sessionStorage.getItem('watchStorage');
		})
		if (localStorage.getItem('phone')) {
			this.currentphone = localStorage.getItem('phone')
		} else {
			this.currentphone = ''
		}
	},
	watch: {
		$route(to, from) {
			//清除监听
			// window.removeEventListener("scroll", this.onScroll, true);
			if (this.inwidth <= 768) {
				this.myState = true
			}
			if (to.name != "home") {
				this.nyCss = true
			} else {
				this.nyCss = false
			}
			console.log('to.meta.title', to.meta.title)
		},
		pagea(to, from) {
			if (to == 'page6') {
				$('.headbox').addClass('dis_play')
			} else {
				$('.headbox').removeClass('dis_play')
			}
		}
	},
	mounted() {
		let that = this
		this.navctive = this.navlist.findIndex(k => k.path === this.$route.path) || 0
		if (that.inwidth > 768) {
			window.addEventListener('scroll', that.onScroll, true)
		}
		that.$nextTick(function () {
			if (that.isfooter == 1) { //1第一版 2第二版
				$('body,html').css("font-family", '微软雅黑')
			} else {
				$('body,html').css("font-family", 'siyuan')
			}
		})
		this.web_info();

	},
	methods: {
		navstatefalse(val) {
			if (val.name == '退出登录') {
				this.outlogin()
				this.navstate = false
				location.reload();
			}
			this.navstate = false
		},
		outlogin() {
			if (localStorage.getItem('phone')) {
				localStorage.setItem('phone', '');
				this.currentphone = ''
				localStorage.setItem('useinfo', '');
				this.$router.push('/')
			}
		},
		scrollToAnchor(anchorName) {
			const anchorElement = document.getElementById(anchorName);
			if (anchorElement) {
				anchorElement.scrollIntoView();
			}
		},
		scrollToAnchors() {
			this.$router.push('/')
		},
		goSeach() {
			this.$router.push({
				path: '/query'
			})
		},
		goJoin() {
			this.$router.push({
				path: '/join'
			})
		},
		change(index, subnavlist) {
			this.closeNav = true
			this.navctive = index
			this.cid = index + 1
			if (subnavlist && subnavlist.length > 0) {
				this.navstate = true
			} else {
				this.navstate = false
			}
		},
		subchange(index) {
			this.navctive = index
			this.cid = index + 1
			this.navstate = false

		},
		homeLink(name, subnavlist) {
			if (name == '首页') {
				window.location.href = '/'
			}
			if (subnavlist && subnavlist.length > 0) {
				this.aa = !this.aa
			}
		},
		navChange(index) {
			this.navctive = index
		},
		logoLink() {
			this.$router.push('/')
			this.navctive = 7;
		},
		web_info() {
		},
		activeSwitch(index, path) {
			console.log(index)
			this.navctive = index
			this.$router.push({
				path: path,
				query: {}
			})
		},
		onScroll() {},
		navMobile() {
			this.navstate = !this.navstate
			if (this.navstate) {
				this.closeNav = false;
			}
		},

		toUrl1(url) {
			window.open('https://chaowei.com.hk/c/index.php')
		},
	},
	beforeDestroy() {
		//清除监听
		let that = this
		window.removeEventListener("scroll", that.onScroll, true);
	}
}
</script>

<style lang="less" scoped>
.headnavlist {
	width: 100%;
	height: 51px;
	color: #000;
	font-size: 15px;
	font-weight: bold;
	border-top: 1px solid #ccc;
	padding-left: 20px;
}

.section {
	width: 100%;
	max-width: 1920px;
	height: 241px;
}

a {
	text-decoration: none;
}

.headrt {
	display: none;
}

.headli {
	display: inline-block;
	height: 100%;
	padding: 0 30px;
	// line-height: 71px;
	cursor: pointer;
	color: #fff;
	// font-family: 宋体;
}

.headbox {
	// position: fixed;
	// left: 0;
	// top: 0;
	width: 100%;
	height: 71px;
	background: #2C88CC !important;
	z-index: 2000;
	color: #333;
	/* padding-top: .1rem; */
	/* border-bottom: .01rem solid rgba(255,255,255,0.2); */
}

.header {
	width: 100%;
	height: 71px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.headlt {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20%;
}

.headlt img {
	width: 1.22rem;
	display: block;
	cursor: pointer;
}

.headamo {
	width: 80%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.headlist {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	color: #fff;
	font-size: 18px;
	height: 100%;
}

.headlist .navitem {
	margin: 0 0.30rem;
	/* padding:.1rem 0; */
	line-height: 1rem;
	/* border-bottom: .03rem solid transparent; */
	font-size: .16rem;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	cursor: pointer;
	position: relative;
	min-width: .7rem;
	height: 1rem;
	font-weight: bold;
}

.nav_cont {
	width: 100%;
	position: absolute;
	text-align: center;
	color: #333;
	transition: color .3s ease-in-out;
	text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}


.navitem:hover .nav_cont {
	color: #ffb900;
}

.headlist .navctive .nav_cont {
	padding-top: 0px;
	/* border-bottom: .03rem solid #e60012; */
	color: #ffb900;
}

.headlist .navctive.navctive1 {
	padding-top: 0px;
	/* border-bottom: .03rem solid #e60012; */
	color: #ffb900;
}

.headlist .navctive1 {
	margin: 0 auto;
}

.headrt {
	height: 1.0rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: .16rem;
	display: none;
}

.headrt div {
	padding: .1rem 0;
	margin-left: 0.1rem;
	cursor: pointer;
}

.headrt img {
	width: .3rem;
	margin-left: 0.3rem;
}

.headrt .headlogo {
	font-weight: bold;
}

.hs_color {
	color: #333 !important;
	border-bottom: .01rem solid #000;
}

.dis_play {
	display: none;
}

.logo1 {
	display: block !important;
}

.logo2 {
	display: none !important;
}

.hs_color .logo1 {
	display: none !important;
}

.hs_color .logo2 {
	display: block !important;
}

// .ny_css {
// 	background: rgba(255, 255, 255, 1);
// 	height: 1rem
// }

// .ny_css .nav_cont {
// 	color: #333;

// }

// .ny_css .headlist .navctive .nav_cont {
// 	color: #ffb900;
// }

// .ny_css .navitem:hover .nav_cont {
// 	color: #ffb900;

// }

// .ny_css .headlist .navitem {
// 	line-height: 1rem;
// }

.home_icon img {
	/* width: .35rem; */
	height: .2rem;
	margin: 0 0.1rem;
	cursor: pointer;
}

.home_txt .a_class {
	font-size: 14px;
	color: #333;
	font-weight: bold;
	margin-left: .5rem;
	margin-right: .5rem;
	cursor: pointer;
	text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}


.phFooterLi {
	display: flex;
	align-items: center;
}

.footlt2 {
	position: relative;
}

.footerBg {
	position: absolute;
	width: 30%;
	left: 1.5rem;
	top: -0.2rem;
}

.footer {
	position: relative;
}

.phcall {
	font-size: 0.3rem;
	color: #ffb900;
	font-weight: bold;
	margin-left: 10px;

}

.phAddress {
	color: #666666;
	font-size: 0.16rem;
	margin-top: 0.3rem;
}

.backWith {
	background-color: #f9f9f9;
}

.footma_ul2 {
	display: flex;
	align-items: center;
	margin-left: .5rem;
}

.footma_ul2 img {
	width: 0.9rem;
	display: block;
	margin-bottom: 0.1rem;
}

.footma_ul2>div {
	text-align: center;
	color: #666666;
	font-size: 0.16rem;
	margin-left: 0.3rem;
}

/* 
第二版 */
.isfooter {

	.footbox,
	.footbtm {
		background: #000;
	}

	.footul li div,
	.footma_ul li {
		color: #fff;
	}

	.footma_ul li img {
		background: #fff;
	}

}

// 第三版
.headbox3 {
	background: rgba(255, 255, 255, 0);
	color: #fff;
}

.headbox3 .nav_cont {
	color: #fff;
	text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}

.headbox3 .home_txt .a_class {
	color: #fff;
	text-shadow: rgba(0, 0, 0, 1) 1px 1px 4px;
}

.isfooter {

	.footbox,
	.footbtm {
		background: #222;
	}

	.footul3 {
		margin-left: 0;
		margin-right: 15%;
	}

	.footul3 li .libor {
		margin-top: 0.1rem;
		width: 0.19rem;
		height: 1px;
		background: #ffb900;
	}

	.footul3 li div {
		color: #898989;
	}

	.footul3 li .litop {
		color: #ffffff;
	}

	.footlt3 .footma_ul {
		margin-top: 0.3rem;
	}

	.footlt3 .footma_ul li {
		color: #898989;
	}

	.foot_cont3 {
		border-top: 1px solid #333333;
	}
}

@media screen and (max-width:5000px) {
	.headbox {
		width: 100%;
		height: 71px;
		background: #2C88CC !important;
		position: fixed;
		top: 0;
	}
}

/*当浏览器尺寸匹配时候的代码：*/
@media screen and (max-width:1410px) {
	.headlist .navitem {
		margin: 0 0.14rem;
	}

	.footul li {
		padding: 0 0.1rem;
		margin-left: 0.2rem;
	}

	.footul {
		margin-left: 5%;
	}

	.footul3 {
		margin-left: 0;
		margin-right: 5%;
	}
}

@media screen and (max-width:1200px) {
	.footul li {
		padding: 0 0.1rem;
		margin-left: 0.15rem;
	}

	.footul {
		margin-left: 2%;
	}

	.footul3 {
		margin-left: 0;
		margin-right: 2%;
	}
}

@media screen and (max-width:768px) {
	.headamo {
		justify-content: flex-end;
	}

	.lines {
		width: 25px;
		position: relative;
		z-index: 9999;
	}

	.lines span {
		width: 100%;
		height: 2px;
		background: #000;
		display: block;
		margin-bottom: 4px;
	}

	.lines span:last-child {
		margin-bottom: 0;
	}

	.headnav {
		width: 100%;
		// padding: 1rem 0 0;
		position: absolute;
		top: 4.9rem;
		left: 0;
		background: #fff;
	}

	.headnavs {
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 255px;
		background: #000;
		opacity: 0.8;
		z-index: 999;
	}

	.navitem {
		width: 90%;
		margin: 0 auto;
		font-size: 14px;
		text-align: center;
		padding: .25rem 0;
		color: #666;
		border-bottom: 1px dashed #e5e5e5;
		cursor: pointer;
	}

	.navitem:hover {
		color: #a21003;
	}

	.navctive {
		background: #eee;
	}

	.headnav {
		top: 0;
		z-index: 999;
		background: #F5F5F5;
	}

	.headlt {
		position: relative;
		z-index: 999;
	}

	.headbox {
		background: #fff;
		padding-bottom: .1rem;
		display: block;
	}

	.lines {
		margin-top: 15px;
	}

	.headlt {
		margin-top: 0;
	}

	.footul {
		display: none;
	}

	.footer .footlt {
		width: auto;
		display: flex;
		justify-content: space-between;
	}

	.footer .footlogo img {
		width: 1.3rem;
	}

	.footma_ul {
		flex: 1;
		margin-left: .5rem;
	}

	.footma_ul li {
		line-height: 24px;
	}

	.footma_ul li img {
		/* width: .25rem; */
		height: 0.9rem;
		margin-right: .2rem;
		margin-top: 2px;
	}

	.foot_cont div {
		width: 100%;
		text-align: center;
	}

	.foot_cont div:last-child {
		display: none;
	}

	.footbtm {
		height: .6rem;
		line-height: .6rem;
	}

}

@media screen and (max-width:480px) {
	.lines {
		margin-top: 6px;
	}

	.headbox {
		position: fixed;
		top: 0;
	}

	.headamo {
		width: 50%;
		padding-right: 30px;
	}

	.headlt {
		width: 50%;
		justify-content: flex-start;
		padding-left: 30px;
	}

	.footer .footlogo img {
		width: 2rem;
	}

	/* .footma_ul li img{
		width: .35rem;
		height: .35rem;
	} */

}
</style>
